import React from 'react';
import { useNavigate } from 'react-router-dom';
import ThinArrow from '../../../general/assets/svg/ThinArrow';
import { routesClient } from '../../../general/routes';

const MiningBitcoinAd: React.FC = () => {
  const navigate = useNavigate();

  const toOffer = () => {
    navigate(routesClient.ffhourSetup.path);
  };

  return (
    <>
      <div className="mining_ad_container">
        <div className="mining_content">
          <h2 className="mining_header poppins-black">
            START <span> MINING BITCOIN</span> IN JUST <span>2 DAYS</span>
          </h2>
          <p className="mining_main_text poppins-regular">
            Purchase an ASIC miner and begin earning within <span>48 hours</span>.
            <br />
            <span>Fast</span>, <span>easy</span>, and <span>accessible</span> to everyone.
          </p>
          <p className="mining_text poppins-regular">
            Now everyone can mine Bitcoin. With our new rapid deployment service, you can start
            earning in as little as two days. Buy your miner today, and we’ll handle the rest—from
            installation to hosting and maintenance.
          </p>
          <div className="icons">
            <div className="space_ship icon"></div>
            <div className="touch icon"></div>
            <div className="people_group icon"></div>
          </div>
        </div>
        <button className="link poppins-light" onClick={toOffer}>
          Get Started Now <ThinArrow />
        </button>
      </div>
      <button className="link-outside poppins-light" onClick={toOffer}>
        Get Started Now
        <ThinArrow />
      </button>
    </>
  );
};

export default MiningBitcoinAd;
