import './Product.scss';

import { useGoogleOneTapLogin } from '@react-oauth/google';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CartProdIcon from '../../../general/assets/svg/CartProdIcon';
import MailIcon from '../../../general/assets/svg/MailIcon';
import MarkerDoneIcon from '../../../general/assets/svg/MarkerDoneIcon';
import PhoneIcon from '../../../general/assets/svg/PhoneIcon';
import ProductHelpIcon from '../../../general/assets/svg/ProductHelpIcon';
import Preloader from '../../../general/components/preloader/Preloader';
import useAddToCart from '../../../general/hooks/useAddToCart';
import useCounter from '../../../general/hooks/useCounter';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import useGoogleCredentials from '../../../general/hooks/useGoogleCredentials';
import useSnackbar from '../../../general/hooks/useSnakbar';
import { queryKeys } from '../../../general/queryKeys';
import { setInfo, setType, types } from '../../../general/redux/reducers/Error-Reducer';
import { RootState } from '../../../general/redux/store';
import { routesClient } from '../../../general/routes';
import createContactsService from '../../../general/services/contacts';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createProductsService from '../../../general/services/products';
import { images } from '../../../general/StaticData';
import { Contacts, ICategory, ProductMax } from '../../../general/types/types';
import { formatToTwoPlaces } from '../../../general/utils/Validations';
import { createCredentials } from '../../components/authorization/google-authorization/GoogleAuth';
import ModalWindow from '../../components/authorization/modal-window/ModalWindow';
import WishlistBtn from '../../components/buttons/wishlist/WishlistBtn';
import Counter from '../../components/inputs/Counter';
import BreadcrumbSchema from '../../components/SEO/BreadcrumbSchemaProps';
import CanonicalLink from '../../components/SEO/CanonicalLink';
import ProductSchema from '../../components/SEO/ProductSchema';
import ProductSlider from '../../components/sliders/product-slider/ProductSlider';
import Features from './Features';
import Tabs from './Tabs';

const miningCharacteristics = [
  {
    name: 'Condition',
    key: 'condition',
  },
  {
    name: 'Algorithm',
    key: 'algorithm',
  },
  {
    name: 'Hashrate',
    key: 'hashrate',
  },
  {
    name: 'Energy efficiency',
    key: 'energyEfficiency',
  },
  {
    name: 'Power Consumption',
    key: 'energyConsumption',
  },
  {
    name: 'Country',
    key: 'countryLocation',
  },
];

const service = [
  'Direct Communication: Personalized, one-on-one support.',
  'Technical Expertise: Solutions for all tech needs.',
  'Profit Analysis: Tailored financial insights for each buyer.',
  'Secure Shopping: Guaranteed buyer protection.',
  'Quality Hardware: Pre-tested for optimal performance.',
  'User-Friendly Interface: No setup, ready to go.',
  'Hosting Options: Top-tier choices from our partners.',
  'Pool Access: Exclusive terms for our clients.',
];

const Product: React.FC = () => {
  const { url } = useParams<{ url: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.clps.cp2);
  const cart = useSelector((state: RootState) => state.pc.pc);
  const setGoogleCredentials = useGoogleCredentials();
  const counterHook = useCounter({ initialValue: 1 });
  const { showSnackbar } = useSnackbar();

  const [mainCharacteristics, setMainCharacteristics] = useState<any[]>([]);
  const [isQuiuckOrder, setIsQuickOrder] = useState<boolean>(false);

  const [isAuth, setIsAuth] = useState<boolean>(false);

  useEffect(() => {
    if (isQuiuckOrder && userId) {
      navigate(routesClient.cartInformation.path);
    }
  }, [isAuth, isQuiuckOrder, userId, navigate]);

  const { data: contacts } = useCustomQuery<Contacts>(queryKeys.COMPANY_CONTACTS, () =>
    createContactsService(clientInstance).getContacts(),
  );

  const { data: product } = useCustomQuery<ProductMax>(
    queryKeys.PRODUCT_CLIENT,
    () => createProductsService(clientInstance).getProductByURL(url as string),
    undefined,
    undefined,
    {
      keepPreviousData: false,
      onError: () => {
        navigate('/404');
        return;
      },
      onSuccess: (data: any) => {
        if (data.status === 204) {
          navigate('/404');
        }
      },
    },
  );

  const addToCart = useAddToCart({ miner: product, cart, counterHook, showSnackbar });

  const { data: currentCategory, refetch: getCurrentCategory } = useCustomQuery<ICategory>(
    queryKeys.PRODUCT_CATEGORY_CUERRENT,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      return data?.find((item: any) => item.id === product?.categoryId);
    },
    undefined,
    {
      enabled: !!product,
    },
  );

  const { refetch: getMainCharacteristics } = useCustomQuery<any>(
    queryKeys.PRODUCT_CLIENT_MAIN_CHARACTERISTICS,
    () =>
      createProductsService(clientInstance).getCharacteristicsMainGeneralOfProduct(
        product?.id as string,
      ),
    undefined,
    undefined,
    {
      enabled: false,
      onSuccess(data) {
        setMainCharacteristics(data);
      },
    },
  );

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      setGoogleCredentials(createCredentials(credentialResponse));
    },
    cancel_on_tap_outside: false,
    disabled: userId !== '',
    use_fedcm_for_prompt: true,
    onError: () => {
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: 'Ooops... Login Failed' },
        }),
      );
    },
  });

  useEffect(() => {
    if (product) {
      getCurrentCategory();
    }
  }, [product, getCurrentCategory]);

  useEffect(() => {
    if (product && currentCategory?.areMiningCharacteristics !== undefined) {
      if (currentCategory?.areMiningCharacteristics) {
        setMainCharacteristics(miningCharacteristics);
      } else {
        getMainCharacteristics();
      }
    }
  }, [product, currentCategory?.areMiningCharacteristics, getMainCharacteristics]);

  const changeImg = (e: MouseEvent<HTMLImageElement>) => {
    const current_img = document.getElementById('product-current-img') as HTMLImageElement;
    if (current_img) {
      current_img.src = e.currentTarget.dataset.url || '';
    }
    e.stopPropagation();
  };

  const breadcrumbItems = [
    { name: 'Home', url: window.location.origin },
    { name: 'Products', url: window.location.origin + routesClient.products.path },
    { name: product?.name as string, url: window.location.href },
  ];

  return (
    <>
      <CanonicalLink href={window.location.href} />
      {isAuth && <ModalWindow isActive={setIsAuth} />}
      {product === null ? (
        <div className="product-section-preloader">
          <Preloader />
        </div>
      ) : (
        <>
          {!product ? (
            <div className="product-section-preloader">
              <Preloader />
            </div>
          ) : (
            <>
              <BreadcrumbSchema items={breadcrumbItems} />
              <ProductSchema product={product} />
              <div className="product-section-top page-container">
                <section className="product-section__gallery">
                  <div className="product-section__gallery__current">
                    <div className="product-section__gallery__current__label">
                      {currentCategory?.areMiningCharacteristics && <p>{product?.condition}</p>}

                      {product?.percentageDiscount > 0 && (
                        <span>- {product?.percentageDiscount}%</span>
                      )}
                    </div>

                    <img
                      id="product-current-img"
                      alt=""
                      src={product?.images[0].url || images.defaultImg}
                    />
                  </div>
                  <div className="product-section__gallery__list">
                    <div className="product-section__slider-image">
                      <ProductSlider setCurrent={changeImg} images={product?.images} />
                    </div>
                  </div>
                </section>
                <section className="product-section__info">
                  <div>
                    <div className="product-section__info-name">
                      <div className="product-section__info-name__firm-actions">
                        <div className="product-section__info-name__name">{product?.name}</div>
                        <div className="product-section__info-name__actions">
                          {/* <CompareBtn product={product} categoryId={product.categoryId} /> */}
                          <WishlistBtn setIsAuthModalOpen={setIsAuth} productId={product.id} />
                        </div>
                      </div>
                      <div className="product-section__info-name__firm">
                        {product?.manufacturer}
                      </div>
                    </div>
                    <div className="product-section__info-price">
                      <div className="product-section__info-price__price">
                        {product?.percentageDiscount === 0 ? (
                          `${formatToTwoPlaces(product?.price * counterHook.count)}$`
                        ) : (
                          <>
                            <span className="product-section__info-price__price-without-discount">{`${formatToTwoPlaces(
                              product?.price * counterHook.count,
                            )}$`}</span>
                            <span className="product-section__info-price__price-with-discount">{`${formatToTwoPlaces(
                              product?.price * counterHook.count -
                                product?.price *
                                  counterHook.count *
                                  (product?.percentageDiscount / 100),
                            )}$`}</span>
                          </>
                        )}
                      </div>
                      <div className="product-section__info-price__available">
                        <span>{product?.amount === 0 ? 'Pre-order' : 'Available'}</span>
                        {product?.batch !== null &&
                          product?.batch !== undefined &&
                          product?.batch !== '' && (
                            <span>
                              {new Date(product?.batch)?.toLocaleString('en-US', {
                                month: 'long',
                                year: 'numeric',
                              })}{' '}
                              Batch
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="product-section__info-characteristics">
                      {mainCharacteristics.map((characteristic) => (
                        <div
                          key={characteristic.name}
                          className="product-section__info-characteristics__characteristics"
                        >
                          <span className="product-section__info-characteristics__characteristics-name">
                            {characteristic.name}
                          </span>
                          <span className="product-section__info-characteristics__characteristics-value">
                            {currentCategory?.areMiningCharacteristics
                              ? (product && characteristic && product[characteristic.key]) || ''
                              : (characteristic && characteristic.value) || ''}
                          </span>
                        </div>
                      ))}
                      <div className="product-section__info-characteristics__counts-buttons">
                        <div className="product-section__info-characteristics__counts-buttons__counts unselectable">
                          <Counter
                            productId={product.id}
                            counterHook={counterHook}
                            syncWithCart={false}
                          />
                        </div>
                        <div
                          className="product-section__info-characteristics__counts-buttons__text"
                          style={
                            counterHook.count >= 15 ? { color: '#25A8CF' } : { color: '#746A81' }
                          }
                        >
                          * If you purchase 25 units or more of this product, you'll receive an
                          extra discount. We'll get in touch with you after you've placed your order
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-section__info-buttons">
                    <button className="product-section__info-buttons-buy " onClick={addToCart}>
                      <CartProdIcon />
                      Add to cart
                    </button>
                    <div className="cart-section-content__buttons__checkout__button-message">
                      <button
                        className="product-section__info-buttons-quick-order"
                        onClick={(e) => {
                          addToCart(e);
                          setIsAuth(true);
                          setIsQuickOrder(true);
                        }}
                      >
                        Quick Order
                      </button>
                    </div>
                  </div>
                </section>
              </div>
              <div className="product-section-guarantee ">
                <div className="product-section-guarantee__list">
                  <Features />
                </div>
              </div>
              <div className="product-section-main-info page-container">
                <Tabs product={product} />
              </div>
              <div className="product-section-contacts-service page-container">
                <div className="product-section-contacts-service-list">
                  <div className="product-section-contacts-service__contacts">
                    <div className="product-section-contacts-service__contacts__svg">
                      <ProductHelpIcon />
                    </div>

                    <div className="product-section-contacts-service__contacts__name">
                      AsicXchange Help Desk
                    </div>
                    <p className="product-section-contacts-service__contacts__text">
                      For assistance and inquiries, please contact us
                    </p>
                    <div className="product-section-contacts-service__contacts__info">
                      <div className="product-section-contacts-service__contacts__info-t">
                        <PhoneIcon />
                        <div>
                          <span>Phone:</span>
                          <span>{contacts?.phoneWeb}</span>
                        </div>
                      </div>
                      <div className="product-section-contacts-service__contacts__info-t">
                        <MailIcon />
                        <div>
                          <span>Email:</span>
                          <span>{contacts?.emailWeb}</span>
                        </div>
                      </div>
                    </div>
                    <p className="product-section-contacts-service__contacts__text">
                      Reach out for any questions or concerns
                    </p>
                  </div>
                  <div className="product-section-contacts-service__service">
                    <div className="product-section-contacts-service__service__name">
                      AsicXchange Services
                    </div>
                    <div className="product-section-contacts-service__service__list">
                      <ul>
                        {service.map((serv, index) => (
                          <li
                            className="product-section-contacts-service__service__list-item"
                            key={index}
                          >
                            <MarkerDoneIcon />
                            <span>{serv}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <p className="product-section-contacts-service__service__text">
                      AsicXchange: Seamless, reliable services for your needs
                    </p>
                  </div>
                </div>
              </div>
              <div className="product-section-also-like"></div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Product;
