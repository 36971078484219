import { routesClient } from '../routes';
import { images } from '../StaticData';
import { CuverseAsicx, ProductMax } from '../types/types';

type Machine = ProductMax | CuverseAsicx;

const getMinerFieldValue = (machine: Machine, field: string) => {
  switch (field) {
    case 'id':
      return machine.id.toString();

    case 'url':
      return 'url' in machine
        ? `${routesClient.product.path}/${machine.url}`
        : routesClient.ffhourSetup.path;

    case 'condition':
      return 'condition' in machine ? machine.condition : 'Unknown';

    case 'description':
      return 'description' in machine ? machine.description : 'Описание отсутствует';

    case 'characteristics':
      return 'characteristics' in machine ? machine.characteristics : [];

    case 'percentageDiscount':
      return 'percentageDiscount' in machine ? machine.percentageDiscount : 0;

    case 'image':
      return 'images' in machine
        ? machine.images[0].url || images.defaultImg
        : machine.picture || images.defaultImg;

    case 'manufacturer':
      return 'manufacturer' in machine ? machine.manufacturer : 'UNKNOWN';

    case 'name':
      return 'name' in machine ? machine.name : machine.model;

    case 'price':
      return machine.price;

    case 'amount':
      return 'amount' in machine ? machine.amount : null;

    case 'batch':
      return 'batch' in machine ? machine.batch : null;

    case 'countryLocation':
      return 'countryLocation' in machine ? machine.countryLocation : 'Not specified';

    case 'hashrate':
      return 'hashrate' in machine
        ? typeof machine.hashrate === 'string'
          ? machine.hashrate
          : `${machine.hashrate} ${machine.unitSymbol}`
        : null;

    case 'energyConsumption':
      return 'energyConsumption' in machine ? machine.energyConsumption : machine.powerConsumption;

    case 'energyEfficiency':
      return 'energyEfficiency' in machine
        ? machine.energyEfficiency
        : parseFloat(machine.efficiency);

    case 'stores':
      return 'stores' in machine ? machine.stores : [];

    case 'cashback':
      return 'cashback' in machine ? machine.cashback : 0;

    case 'code':
      return 'code' in machine ? machine.code : 'No code';

    case 'coin':
      return 'coin' in machine ? machine.coin : 'Unknown';

    case 'count':
      return 'count' in machine ? machine.count : null;

    case 'dailyElectricityFee':
      return 'dailyElectricityFee' in machine ? machine.dailyElectricityFee : null;

    case 'efficiency':
      return 'efficiency' in machine ? machine.efficiency : null;

    case 'estDailyOutput':
      return 'estDailyOutput' in machine ? machine.estDailyOutput : null;

    case 'isNew':
      return 'isNew' in machine ? machine.isNew : null;

    case 'managementFee':
      return 'managementFee' in machine ? machine.managementFee : null;

    case 'oldPrice':
      return 'oldPrice' in machine ? machine.oldPrice : null;

    case 'period':
      return 'period' in machine ? machine.period : null;

    case 'picture':
      return 'picture' in machine ? machine.picture : machine.images[0]?.url;

    case 'poolName':
      return 'poolName' in machine ? machine.poolName : null;

    case 'powerConsumption':
      return 'powerConsumption' in machine ? machine.powerConsumption : machine.energyConsumption;

    case 'pricePerKw':
      return 'pricePerKw' in machine ? machine.pricePerKw : null;

    case 'sellLeader':
      return 'sellLeader' in machine ? machine.sellLeader : false;

    case 'soldOut':
      return 'soldOut' in machine ? machine.soldOut : false;

    case 'sort':
      return 'sort' in machine ? machine.sort : null;

    case 'startDelay':
      return 'startDelay' in machine ? machine.startDelay : null;

    case 'startsAt':
      return 'startsAt' in machine ? machine.startsAt : null;

    case 'unitSymbol':
      return 'unitSymbol' in machine ? machine.unitSymbol : '';

    default:
      return null; // обработка для несуществующего поля
  }
};

export const partners = {
  non: '',
  cuversePartners: 'cuversePartners',
};

export const minerFrom = (miner: any) => {
  if ('seller' in miner) {
    if (miner.seller === partners.cuversePartners) return 'Seller: AsicXchange (48 hours offer)';
  } else {
    return 'Seller: AsicXchange';
  }
};

export default getMinerFieldValue;
