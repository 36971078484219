import './Cart.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import BucketIcon from '../../../general/assets/svg/BucketIcon';
import useCounter from '../../../general/hooks/useCounter';
import { removeCartItem } from '../../../general/redux/reducers/ClientCart-Reducer';
import { CartItem } from '../../../general/types/types';
import getMinerFieldValue from '../../../general/utils/getMinerFieldValue';
import { formatToTwoPlaces } from '../../../general/utils/Validations';
import Counter from '../../components/inputs/Counter';

interface TableRowProps {
  row: CartItem;
  className: string;
}

const TableRow: React.FC<TableRowProps> = ({ row, className }) => {
  const dispatch = useDispatch();
  const counterHook = useCounter({ initialValue: row.c });

  const deleterRow = () => {
    dispatch(removeCartItem(getMinerFieldValue(row.p, 'id')));
  };

  return (
    <li className={className}>
      <div className="list_1024__item__img">
        <Link
          to={getMinerFieldValue(row.p, 'url')}
          onClick={() => {
            localStorage.setItem('24setupOfferAnchor', 'miners');
            localStorage.setItem('24setupOfferSelectedMinerId', row.p.id.toString());
          }}
        >
          <div
            className="img"
            style={{
              backgroundImage: `url(${getMinerFieldValue(row.p, 'image')})`,
            }}
          ></div>
        </Link>
      </div>
      <div className="list_1024__item__content__info">
        <div className="list_1024__item__info">
          <div style={{ marginBottom: '5px' }}>
            <span className="list_1024__item__info__name">{getMinerFieldValue(row.p, 'name')}</span>
            <span className="list_1024__item__info__avilable">
              {getMinerFieldValue(row.p, 'amount') === 0 ? 'Pre-order' : 'Available'}
            </span>
          </div>
          <div className="product-section__info-characteristics__counts-buttons__counts unselectable">
            <Counter productId={row.p.id.toString()} counterHook={counterHook} />
          </div>
        </div>

        <div className="list_1024__item__action-price">
          <div className="list_1024__item__action-price__action">
            <button onClick={deleterRow}>
              <BucketIcon />
            </button>
          </div>
          <div className="list_1024__item__action-price__price">
            {getMinerFieldValue(row.p, 'percentageDiscount') === 0 ? (
              <span style={{ fontWeight: 600 }}>
                ${formatToTwoPlaces(row?.p?.price * counterHook.count)}
              </span>
            ) : (
              <>
                <span className="price-without-discount">{`$${formatToTwoPlaces(
                  row?.p?.price * counterHook.count,
                )}`}</span>
                <span className="price-with-discount" style={{ fontWeight: 600 }}>
                  {`$${formatToTwoPlaces(
                    row?.p?.price * counterHook.count -
                      row?.p?.price *
                        counterHook.count *
                        (getMinerFieldValue(row.p, 'percentageDiscount') / 100),
                  )}`}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default TableRow;
