import React, { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from '../../../general/contexts/ThemeContext';
import Header from '../../components/header/Header';

import { useDispatch } from 'react-redux';
import ArrowLink from '../../../general/assets/svg/ArrowLink';
import BitcoinIcon from '../../../general/assets/svg/BitcoinIcon';
import HoursConnection from '../../../general/assets/svg/HoursConnection';
import LikeIcon from '../../../general/assets/svg/LikeIcon';
import SuccessIcon from '../../../general/assets/svg/SuccessIcon';
import TwoArrowIcon from '../../../general/assets/svg/TwoArrowIcon';
import useScrollTo from '../../../general/hooks/useScrollTo';
import { setIsOpened } from '../../../general/redux/reducers/GetInTouch-Reducer';
import ModalWindow from '../../components/authorization/modal-window/ModalWindow';
import ContactForm from '../../components/contact-form/ContactForm';
import CanonicalLink from '../../components/SEO/CanonicalLink';
import QA from '../help-center/faq-and-qa/QA';
import './48HourSetup.scss';
import MinersSuggestion from './miner-suggestion/MinersSuggestion';

interface IPrevelegion {
  icon: React.ReactNode;
  header: string;
  info: string;
}

interface IContractHightLights {
  iconUrl: string;
  header: string;
  info: string;
}

const FFHourSetup: React.FC = () => {
  const dispatch = useDispatch();
  const scrollTo = useScrollTo();
  const videoRef = useRef<HTMLVideoElement>(null);
  const context = useContext(ThemeContext);
  const [isAuth, setIsAuth] = useState(false);

  const prevelegious: IPrevelegion[] = [
    {
      icon: <BitcoinIcon />,
      header: 'Buy from 1 miner',
      info: 'Buy <span>one or more miners</span> to match your mining objectives. Select the number of miners based on your <span>operational goals</span>.',
    },
    {
      icon: <TwoArrowIcon />,
      header: 'Quick Start',
      info: 'Your miners will be operational within <span>48 hours</span> after payment, ensuring a <span>fast setup</span> for your mining activities.',
    },
    {
      icon: <LikeIcon />,
      header: 'Miner solutions',
      info: 'We manage it all: from <span>purchasing</span> to <span>installation</span> and <span>hosting</span>. You receive a <span>ready solution</span> with no need for technical work.',
    },
    {
      icon: <SuccessIcon />,
      header: 'Verified hashrate',
      info: 'Our hashrate is independently verified by <span>leading mining pools</span>, ensuring <span>transparent</span> and <span>reliable performance</span>.',
    },
  ];

  const minersWorkPlaces: { text: string }[] = [
    {
      text: '<span style="color: #25A8CF;">Modern Data Centers:</span> We have 4 state-of-the-art data centers using low-cost, sustainable energy.',
    },
    {
      text: '<span style="color: #25A8CF;">Ideal Conditions:</span> Professional cooling systems keep miners at optimal temperatures all year.',
    },
    {
      text: '<span style="color: #25A8CF;">Comprehensive Security:</span> Each facility has full video surveillance, visitor registration, and extensive  monitoring.',
    },
    {
      text: '<span style="color: #25A8CF;">Reliable Network:</span> Fast local network with 2 Tbit/s speed, secure isolated connections for each customer, and  strong malware protection.',
    },
  ];

  const contractHighlights: IContractHightLights[] = [
    {
      iconUrl: 'https://s3.tebi.io/asicxchange-media/d03549c1-2ae0-4915-94e9-3a69d901d2d6',
      header: 'Secure Your Risks',
      info: 'All contract terms are fixed for 3 years, guaranteeing stable electricity prices.',
    },
    {
      iconUrl: 'https://s3.tebi.io/asicxchange-media/0a3b6f89-4f0e-4664-8742-9eb4332a4fa4',
      header: 'Flexible Terms',
      info: 'Extend your contract after it expires or trade in your miner for a newer model.',
    },
    {
      iconUrl: 'https://s3.tebi.io/asicxchange-media/d03549c1-2ae0-4915-94e9-3a69d901d2d6',
      header: 'Express Repairs',
      info: 'We handle minor repairs quickly at no extra cost, keeping your miner running smoothly.',
    },
    {
      iconUrl: 'https://s3.tebi.io/asicxchange-media/719b26fe-500e-4bae-b0d7-e3b88a4d9794',
      header: 'Extended Warranty',
      info: 'Get 2 years of warranty—1 year from the manufacturer and an additional year from us.',
    },
    {
      iconUrl: 'https://s3.tebi.io/asicxchange-media/5ab524b0-b935-46b2-977d-85c98df65aba',
      header: 'All-Inclusive Costs',
      info: 'All taxes, delivery, and customs fees are included in the miner price—no hidden costs.',
    },
  ];

  useEffect(() => {
    const minersAnchor = localStorage.getItem('24setupOfferAnchor');
    if (minersAnchor) {
      scrollTo(minersAnchor);
      localStorage.removeItem('24setupOfferAnchor');
    }
  }, [scrollTo]);

  useEffect(() => {
    const playVideo = async () => {
      if (videoRef.current) {
        try {
          await videoRef.current.play();
        } catch (error) {
          console.error('Ошибка при воспроизведении видео: ', error);
        }
      }
    };

    const emulateUserInteraction = () => {
      if (videoRef.current) {
        videoRef.current.muted = true; // Убедимся, что видео выключено
        videoRef.current.dispatchEvent(new Event('click')); // Эмулируем клик по видео
      }
    };

    if (videoRef.current) {
      // Эмуляция взаимодействия с пользователем
      emulateUserInteraction();

      // Попробовать воспроизвести видео сразу
      playVideo();

      const playInterval = setInterval(() => {
        if (videoRef.current?.paused) {
          playVideo();
        } else {
          clearInterval(playInterval); // Если видео воспроизводится, остановить повторные попытки
        }
      }, 1000);

      return () => clearInterval(playInterval); // Очистка интервала при размонтировании
    }
  }, []);

  // Проверяем, что контекст определен
  if (!context) {
    throw new Error('ThemeContext must be used within a ThemeProvider');
  }

  // Деструктурируем theme и setTheme из context
  const { theme, setTheme } = context;

  return (
    <>
      <CanonicalLink href={window.location.href} />
      {isAuth && <ModalWindow isActive={setIsAuth} />}
      <div className="suggestion-page">
        <div className="suggestion-page-content">
          <div className="video-background">
            <video ref={videoRef} playsInline autoPlay loop>
              <source
                src="https://s3.tebi.io/asicxchange-media/6c0711cc-72e4-4106-a060-3771988271df"
                type="video/mp4"
              />
            </video>
          </div>

          <div className="page-container-header">
            <Header customClassName="inline" theme={theme} setTheme={setTheme} />

            <section className="main-block page-container">
              <div className="offer-container">
                <div className="top">
                  <h2 className="offer-title poppins-medium">SPECIAL OFFER</h2>
                  <HoursConnection />
                </div>
                <p className="text poppins-medium">FOR BITCOIN MINERS</p>
                <ul className="offer-options">
                  <li className="poppins-medium">Buy a miner</li>
                  <li className="poppins-medium">Get quick setup</li>
                  <li className="poppins-medium">Start Earning</li>
                </ul>
                <div className="action-buttons">
                  <button
                    className="start-mining-btn section-btn-gradient poppins-medium"
                    onClick={() => {
                      scrollTo('miners');
                    }}
                  >
                    Start Mining
                  </button>
                  <button
                    className="contact-us-btn poppins-medium"
                    onClick={() => {
                      dispatch(setIsOpened(true));
                      scrollTo('get-in-touch');
                    }}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section className="prevelegious-section page-container">
          {prevelegious.map((item, index) => (
            <div className="prevelegia-container" style={{ marginTop: '2rem' }} key={index}>
              <div className="prevelegia">
                <div className="content">
                  <div className="header">
                    {item.icon}
                    <span className="poppins-medium">{item.header}</span>
                  </div>
                  <div className="info">
                    <span
                      className="info-text poppins-regular"
                      dangerouslySetInnerHTML={{ __html: item.info }}
                    />
                  </div>
                </div>
                <div className="arrow">
                  <ArrowLink />
                </div>
              </div>
            </div>
          ))}
        </section>
        <section id="miners" className="miners-section page-container">
          <div className="header">
            <h2 className="poppins-regular">
              <span>CHOOSE</span> YOUR MINER
            </h2>
            <p className="poppins-regular">
              Select from our range of ASIC miners with professional hosting included. Each miner is
              optimized for profitability and quick setup.
            </p>
          </div>
          <div className="miners-content">
            <MinersSuggestion />
          </div>
        </section>
        <section className="contracts-section page-container">
          <div className="header">
            <h2 className="poppins-regular">
              CONTRACT <span>HIGHLIGHTS</span>
            </h2>
          </div>
          <div className="miners-highlights">
            {contractHighlights.map((item, index) => (
              <div
                key={index}
                className={`miners-grid-item ${index === 0 ? 'highlight-item' : ''}`}
              >
                <div className="header">
                  <div className="icon" style={{ backgroundImage: `url(${item.iconUrl})` }}></div>
                  <h3 className="poppins-medium">{item.header}</h3>
                </div>
                <p className="poppins-light">{item.info}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="miners-work-section page-container">
          <div className="header">
            <h2 className="poppins-regular">
              WHERE WILL <span>MY MINERS</span> WORK?
            </h2>
            <p className="poppins-regular">
              Your miners will operate in our advanced mining facilities, designed to provide the
              best environment for Bitcoin mining.
            </p>
          </div>
          <div className="miners-work-blocks">
            {minersWorkPlaces.map((item, index) => (
              <div key={index} className="miners-work-block">
                <ArrowLink />
                <p className="poppins-regular" dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            ))}
          </div>
        </section>
        <div className="qa-section">
          <QA grpoupCondition="GENERAL QUESTIONS" />
        </div>
        <section className="mining-data-section page-container">
          <div className="header">
            <h2 className="poppins-regular">
              <span>MINING DATA</span> AND STATISTIC
            </h2>
            <p className="poppins-regular">
              All mining data and statistics will be available directly in your personal account on
              our website, giving you easy access to monitor your mining performance.
            </p>
            <button
              className="start-mining-btn section-btn-gradient"
              onClick={() => {
                dispatch(setIsOpened(true));
                scrollTo('miners');
              }}
            >
              Start Mining
            </button>
          </div>
          <div className="picture-container">
            <div className="picture" />
          </div>
        </section>

        <ContactForm />
      </div>
    </>
  );
};

export default FFHourSetup;
