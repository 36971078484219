import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '../../../../general/assets/svg/ArrowDropDownIcon';
import EnergyConsumption from '../../../../general/assets/svg/EnergyConsumption';
import HashRateIcon from '../../../../general/assets/svg/HashRateIcon';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setSelectedCuverseMinerId } from '../../../../general/redux/reducers/ClinetPanel-Reducer';
import { RootState } from '../../../../general/redux/store';
import createCryptosService from '../../../../general/services/cryptos';
import createCuverseService from '../../../../general/services/cuverse';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import { CryptoData, CuverseAsicx } from '../../../../general/types/types';
import { partners } from '../../../../general/utils/getMinerFieldValue';
import SelectedMinerInfo from './SelectedMinerInfo';

const MinersSuggestion: React.FC = () => {
  const dispatch = useDispatch();
  const selectedMinerId = useSelector(
    (state: RootState) => state.clientPanel.cuverseMinerSelectedId,
  );

  const { data: cuverseMiners } = useCustomQuery<CuverseAsicx[]>(
    queryKeys.CUVERSE_MINERS,
    () => {
      return createCuverseService(clientInstance).getCuverseMiners();
    },
    (data) => {
      return data.map((miner) => ({
        ...miner,
        id: miner.id.toString(),
        seller: partners.cuversePartners,
      }));
    },
    undefined,
    {
      onSuccess(data: any) {
        const storedMinerId = localStorage.getItem('24setupOfferSelectedMinerId');

        if (!storedMinerId) {
          dispatch(setSelectedCuverseMinerId(data[0].id));
        } else {
          dispatch(setSelectedCuverseMinerId(storedMinerId));
        }
      },
    },
  );

  const { data: cryptosList } = useCustomQuery<CryptoData[]>(queryKeys.CRYPTOS_LIST_OBJ, () =>
    createCryptosService(clientInstance).getCryptosList(),
  );

  const handleMinerSelect = (minerId: string) => {
    if (selectedMinerId !== minerId) {
      dispatch(setSelectedCuverseMinerId(minerId));
    }
  };

  // Находим выбранного майнера
  const selectedMiner = cuverseMiners?.find(
    (miner) => miner.id.toString() === (selectedMinerId as string),
  );

  return (
    <>
      <div className="miners-list">
        {cuverseMiners?.map((miner: CuverseAsicx) => (
          <div
            key={miner.id}
            className={`miner-item ${selectedMinerId === miner.id ? 'selected' : ''}`}
            onClick={() => handleMinerSelect(miner.id)}
          >
            <div className="info-container-inline">
              <div className="info-container">
                <div
                  className="icon"
                  style={{
                    backgroundImage: `url(https://back.cuverse.com/images/miner-page/1346.jpg)`,
                  }}
                />
                <div className="info">
                  <span className="name poppins-regular">{miner.model}</span>
                  <div className="miner-specs">
                    <div className="info__icons-icon">
                      <HashRateIcon stroke="#B8C6CC" />
                      <span className="value poppins-regular">{miner.hashrate}</span>
                      <span className="unit poppins-regular">{miner.unitSymbol}</span>
                    </div>
                    <div className="info__icons-icon">
                      <EnergyConsumption stroke="#B8C6CC" />
                      <span className="value poppins-regular">{miner.powerConsumption}</span>
                      <span className="unit poppins-regular">kW</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="arrow">
                <ArrowDropDownIcon />
              </div>
            </div>
            <div
              className={`miner-details-container ${selectedMinerId === miner.id ? 'open' : ''}`}
              onClick={(event) => event.stopPropagation()}
            >
              <SelectedMinerInfo miner={miner} cryptosList={cryptosList as CryptoData[]} />
            </div>
          </div>
        ))}
      </div>

      {selectedMiner && (
        <SelectedMinerInfo miner={selectedMiner} cryptosList={cryptosList as CryptoData[]} />
      )}
    </>
  );
};

export default MinersSuggestion;
